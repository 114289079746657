@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;

  @apply bg-background min-w-[1260px];
}

/* 全局样式 */
.hide-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

a.disabled-link {
  color: rgb(0 0 0 / 25%);
  cursor: no-drop;
}

/* ./end */

/* webkit滚动条 */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgb(0 0 0 / 15%);
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* ./end */

// ant table 样式覆盖
.ant-table:not(.ant-table-bordered) {
  th:first-child {
    border-radius: 0 0 0 8px;
  }

  th:last-child {
    border-radius: 0 0 8px;
  }
}

// ant menu 样式覆盖
// .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
//   .ant-menu-item::after {
//     @apply hidden;
//   }

//   .ant-menu-submenu-title {
//     @apply rounded;
//   }

//   & > .ant-menu-item,
//   .ant-menu-submenu.ant-menu-submenu-inline {
//     & > .ant-menu.ant-menu-sub.ant-menu-inline {
//       & > .ant-menu-item {
//         @apply rounded;
//       }

//       @apply bg-white;
//     }

//     @apply w-auto mx-4 rounded;
//   }
// }
